<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${data_table.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.rha_demands.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="formAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Fecha'" />
                          <th v-text="'Tipo'" />
                          <th v-text="'Usuario'" />
                          <th v-text="'Fecha solución'" />
                          <th v-text="'Usuario solución'" />
                          <th v-text="'Acción'" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, i) in data_table" :key="i">
                          <td
                            class="font-weight-bold"
                            v-text="data_table.length - i"
                          />
                          <td v-text="v.created_at" />
                          <td>
                            <v-alert
                              dense
                              :type="
                                v.comment_response
                                  ? 'success'
                                  : v.rha_demand_type_id === 4
                                  ? 'error'
                                  : 'warning'
                              "
                            >
                              {{ v.rha_demand_type }}
                            </v-alert>
                          </td>
                          <td v-text="v.user.email" />
                          <td v-text="v.created_response_at" />
                          <td
                            v-text="
                              v.comment_response ? v.user_response.email : ''
                            "
                          />
                          <td width="200px">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="secondary"
                                  @click.prevent="formEditView(v.id, false)"
                                >
                                  <v-icon v-text="'mdi-eye'" />
                                </v-btn>
                              </template>
                              <span v-text="'Ver'" />
                            </v-tooltip>
                            <v-tooltip
                              left
                              v-if="
                                login.permissions.rha_demands.update &&
                                !v.comment_response
                              "
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="info"
                                  @click.prevent="formEditView(v.id, true)"
                                >
                                  <v-icon v-text="'mdi-pencil'" />
                                </v-btn>
                              </template>
                              <span v-text="'Editar'" />
                            </v-tooltip>
                            <v-tooltip
                              left
                              v-if="login.permissions.rha_demands.delete"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="error"
                                  @click.prevent="desactivate(v.id)"
                                >
                                  <v-icon v-text="'mdi-database-remove'" />
                                </v-btn>
                              </template>
                              <span v-text="'Eliminar'" />
                            </v-tooltip>
                            <v-tooltip
                              left
                              v-if="
                                v.automatic_authorization && v.hour_validation
                              "
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_pdf + '/cartaexpres/demand/' + v.id
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Ver carta'" />
                            </v-tooltip>
                            <v-tooltip left v-if="v.comment_response === null">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  @click.prevent="demandResponse(v.id)"
                                >
                                  <v-icon v-text="'mdi-send'" />
                                </v-btn>
                              </template>
                              <span v-text="'Responder'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog STOREUPDATE start -->
    <v-dialog v-model="form_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :loading="loading ? 'grey darken-2' : false"
        :disabled="loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="data.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click.prevent="formDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'PETICIÓN'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items />
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        v-if="!data.demand_response"
                        cols="12"
                        xs="12"
                        md="8"
                      >
                        <v-autocomplete
                          label="Tipo*"
                          v-model="data.rha_demand_type_id"
                          :rules="rules.required"
                          :items="demand_types"
                          item-value="id"
                          :item-text="(i) => i.demand_type"
                          :loading="demand_types_loading"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="data.rha_demand_type_id == 2"
                        cols="12"
                        xs="12"
                        md="4"
                      >
                        <v-text-field
                          label="Monto total gastado"
                          v-model="data.amount"
                          :rules="rules.required"
                          type="number"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" v-if="!data.demand_response">
                        <v-textarea
                          v-model="data.comment"
                          label="Comentario"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" v-if="data.demand_response">
                        <v-textarea
                          v-model="data.comment_response"
                          label="Comentario solución*"
                          :rules="rules.required"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        v-for="(document, i) in data.documents"
                        :key="i"
                      >
                        <v-row v-if="document.active" dense>
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :label="`Tipo (${i + 1})*`"
                              v-model="document.document_id"
                              :rules="rules.required"
                              :items="documents"
                              item-value="id"
                              :item-text="(item) => item.document"
                              :loading="documents_loading"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="5">
                            <v-text-field
                              label="Descripción"
                              v-model="document.description"
                              type="text"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="3">
                            <v-file-input
                              label="Archivo digital*"
                              v-model="document.file"
                              accept=".pdf, .docx, .doc, .txt, .xlsx, .xls"
                              :rules="data.id ? [] : rules.required"
                              show-size
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            md="1"
                            class="text-center"
                            align-self="center"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  text
                                  icon
                                  x-small
                                  color="error"
                                  @click="fileDelete(i)"
                                >
                                  <v-icon medium v-text="'mdi-close'" />
                                </v-btn>
                              </template>
                              <span v-text="`Eliminar documento (${i + 1})`" />
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          @click="fileAdd"
                          color="warning"
                          :loading="loading"
                          x-small
                          block
                          dark
                        >
                          <v-icon left v-text="'mdi-file-plus'" />
                          Agregar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  :tLabel="data.id ? 'Editar' : 'Agregar'"
                  tPosition="top"
                  :icon="`mdi-${data.id ? 'pencil' : 'plus'}`"
                  :color="data.id ? 'info' : 'success'"
                  size="small"
                  :click="formSubmit"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog STOREUPDATE end -->
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click.prevent="view_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'PETICIÓN'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3">
                      <ViewData
                        label="Tipo"
                        :value="data.demand_type.demand_type"
                      />
                    </v-col>
                    <v-col
                      v-if="data.rha_demand_type_id == 2"
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <ViewData
                        label="Monto"
                        :value="numberFormat(data.amount)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <ViewData label="Fecha" :value="data.created_at" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <ViewData label="Usuario" :value="data.user.email" />
                    </v-col>
                    <v-col cols="12">
                      <ViewData
                        label="Comentario"
                        :value="data.comment"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="data.comment_response">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'SOLUCIÓN'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="Fecha"
                        :value="data.created_response_at"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="Usuario"
                        :value="data.user_response.email"
                      />
                    </v-col>
                    <v-col cols="12">
                      <ViewData
                        label="Comentario"
                        :value="data.comment_response"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense v-for="(document, i) in data.documents" :key="i">
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        :label="`Tipo (${i + 1})`"
                        :value="document.document ? document.document : ''"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData
                        label="Descripción"
                        :value="document.description"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            dark
                            :href="
                              url_documents + '/rha_demands/' + document.url
                            "
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
  URL_PDF,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      api_url: "rha/demand",
      url_pdf: URL_PDF,
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rha_id: this.$route.params.id,
      loading: false,
      rules: rules(),
      data_table: [],
      data: {},
      form_dialog: false,
      view_dialog: false,
      demand_types: [],
      demand_types_loading: true,
      documents: [],
      documents_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getDataTable() {
      this.loading = true;

      index(this.api_url, this.login.token, [
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.data_table = response.data;
        this.loading = false;
      });

      this.data_table = [];
    },
    defaultData() {
      this.data = {
        id: null,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.rha_id,
        demand_response: false,
        amount: 0,
        documents: [],
      };
    },
    formAdd() {
      this.form_dialog = true;
    },
    formEditView(id, edit) {
      show(this.api_url, this.login.token, id).then((response) => {
        this.data = response;

        if (edit) {
          this.form_dialog = true;
          this.data.demand_response = false;
        } else {
          this.view_dialog = true;
        }
      });
    },
    formDialogClose() {
      this.form_dialog = false;
      this.defaultData();
      this.$refs.form_submit.resetValidation();
    },
    formSubmit() {
      if (this.$refs.form_submit.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma ${this.data.id ? "editar" : "agregar"} el registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                this.api_url,
                this.login.token,
                this.data.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.getDataTable();
                  this.defaultData();
                  this.form_dialog = false;
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    desactivate(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(this.api_url, this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getDataTable()
                  : console.log(response.message);
              }
            );
          }
        });
    },
    demandResponse(id) {
      show(this.api_url, this.login.token, id).then((response) => {
        this.data = response;
        this.data.demand_response = true;

        this.formAdd();
      });
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        document_id: null,
        active: true,
        description: "",
        file: null,
      });
    },
    fileDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    this.defaultData();
    this.getDataTable();

    catalog("rha_demand_types", this.login.token, "demand_type").then(
      (response) => {
        this.demand_types = response;
        this.demand_types_loading = false;
      }
    );

    index("documents/3", this.login.token, []).then((response) => {
      this.documents = response.data;
      this.documents_loading = false;
    });
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>